import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×4\\@85% 1RM  `}<em parentName="p">{`&  `}</em>{`Pendlay Row 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 rounds of:`}</p>
    <p>{`1:00 work, 2:00 rest:`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`Max S2OH reps (95/65) (RX+ 115/75)`}</p>
    <p>{`Score = total S2OH reps`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`Bonus`}{`*`}{`*`}</strong></p>
    <p>{`Death by Ring Muscle Ups`}</p>
    <p><em parentName="p">{`1 Muscle Up on minute 1, 2 Muscle Ups on minute 2, etc. until failure.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday is our next free workout at 9:00 & 10:00am so bring a
friend and start the new year off right!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      